/*
 *
 */

// Vendors
import React from 'react';
import { Link } from 'gatsby';
import { useIntl, FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

// Components
import Section from 'components/section';
import Press from 'components/press';
import Partners from 'components/partners';
import Text from 'components/text';
import Button from 'components/button';
import SectionBox from 'components/section-box';
import HomeIntro from 'components/home-intro';
import RegionList from 'components/region-list';
import ShareBox from 'components/sharebox';
import { DEFAULT_LANG } from 'i18n';
import AnnouncementSection from 'components/announcement';

// Data
import regions from 'data/regions.json';

/*
 *
 */

export default function Home () {

	const intl = useIntl();
	const lang = intl.locale;

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.home.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.home.meta_desc' })} />
			</Helmet>
			<Section>
				<HomeIntro
					title={intl.formatMessage({ id: 'page.home.title' })}
					subtitle={intl.formatMessage({ id: 'page.home.tagline' })}
					hashtag={intl.formatMessage({ id: 'page.home.hashtag'})}
				/>
			</Section>

			<AnnouncementSection>
				<p>{intl.formatMessage({ id: 'page.home.alert.carte.desc'})}<a href="/carte">{intl.formatMessage({ id: 'page.home.alert.carte.link'})}</a></p>
			</AnnouncementSection>


			<Section>

				<Text>
					<h2>
						{intl.formatMessage({ id: 'page.home.section.1.title' })}
					</h2>
					<div />
				</Text>
				<RegionList regions={regions} />
				<ShareBox
					title="Partagez la liste des restaurants ouvert à vos proches"
					subtitle="#AideAuxRestaurateurs #ToujoursLaPourVousLivrer"
					websiteToShare="http://www.aide-aux-restaurateurs.fr"
				/>
			</Section>


			<Section>
					<Text>
						<h2>
							{intl.formatMessage({ id: 'page.home.press.title' })}
						</h2>
						<div />
					</Text>
						<Press
						press={[
							{
								name: 'Le Monde',
								logo: '/img/lemonde.png',
								url: 'https://www.lemonde.fr/m-styles/article/2020/05/13/restos-ouverts-les-sites-et-applications-qui-permettent-de-s-y-retrouver_6039538_4497319.html'
							},
							{
								name: 'LCI',
								logo: '/img/lci.png',
								url: 'https://www.lci.fr/high-tech/coronavirus-un-resto-pres-de-chez-vous-qui-livre-une-bonne-action-a-double-titre-solidaire-confinement-restaurant-bons-d-achat-2151639.html'
							},
							{
								name: 'Le Chef',
								logo: '/img/lechef.png',
								url: 'https://www.lechef.com/au-quotidien/2020-04-29-la-foodtech-au-secours-de-la-restauration-avec-aide-aux-restaurateurs-2/'
							},
							{
								name: 'Grazia',
								logo: '/img/grazia.png',
								url: 'https://www.grazia.fr/cuisine/actualite-food/les-restaurants-qui-livrent-a-paris-956483'
							},
							{
								name: '01Net',
								logo: '/img/01net.png',
								url: 'https://www.01net.com/actualites/confinement-les-sites-pour-aider-vos-restaurants-et-bars-preferes-1893533.html'
							}
						]}
					/>
			</Section>

			<Section>
				<Text>
					<h2>{intl.formatMessage({ id: 'page.home.section.howtohelp.title' })}</h2>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.intro' })}</p>
					<div></div>
				</Text>
				<SectionBox>	
					<h3>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_1' })}</h3>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_1.p_1' })}</p>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_1.p_2' })}</p>
					<Link 
						to={`${lang !== DEFAULT_LANG ? `/${lang}` : ''}/list`}
						onClick={e => {		
					        // Lets track that custom click		
					        trackCustomEvent({		
					          // string - required - The object that was interacted with (e.g.video)		
					          category: "Button",		
					          // string - required - Type of interaction (e.g. 'play')		
					          action: "View List of Restaurant",		
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')		
					          label: "Home - How to Help"		
					        })		
			      		}}>
						<span>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_1.link' })}</span>
						<i className="arrow right"></i>
					</Link>
				</SectionBox>
				<SectionBox>
					<h3>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_2' })}</h3>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_2.p_1' })}</p>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_2.p_2' })}</p>
				</SectionBox>
				<SectionBox>
					<h3>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_3' })}</h3>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_3.p_1' })}</p>
					<p>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_3.p_2' })}</p>
					<a 
						rel="nofollow noindex noreferrer noopener" 
						target="_blank" 
						href="https://webapp.wearephenix.com/fr/"
						onClick={e => {		
					        // Lets track that custom click		
					        trackCustomEvent({		
					          // string - required - The object that was interacted with (e.g.video)		
					          category: "Button",		
					          // string - required - Type of interaction (e.g. 'play')		
					          action: "Visit Wearephenix",		
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')		
					          label: "Home - How to Help"		
					        })		
			      		}}>
						<span>{intl.formatMessage({ id: 'page.home.section.howtohelp.q_3.link' })}</span>
						<i className="arrow right"></i>
					</a>
				</SectionBox>
			</Section>
			<Section>
				<Text>
					<h2>{intl.formatMessage({ id: 'page.home.section.4.title' })}</h2>
					<p>
						<FormattedMessage
							id="page.home.section.4.p_1"
							values={{
								a: (...chunks) => (
									<a href="mailto:hello@aide-aux-restaurateurs.fr">
										{chunks}
									</a>
								)
							}}
						/>
					</p>
					<p style={{ textAlign: 'center' }}>
						<Button 
							to={`${lang !== DEFAULT_LANG ? `/${lang}` : ''}/ajouter-un-restaurant`}
							onClick={e => {		
					        // Lets track that custom click		
					        trackCustomEvent({		
					          // string - required - The object that was interacted with (e.g.video)		
					          category: "Button",		
					          // string - required - Type of interaction (e.g. 'play')		
					          action: "Add Restaurant",		
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')		
					          label: "Homepage - Add Section"		
					        })		
					      }}>
							{intl.formatMessage({ id: 'page.home.section.4.add_restaurant' })}
						</Button>
					</p>
				</Text>
			</Section>
			<Section>
				<Text>
					<h2>{intl.formatMessage({ id: 'page.home.section.faq.title' })}</h2>
					<p>{intl.formatMessage({ id: 'page.home.section.faq.subtitle' })}</p>
					<p>
						<Button to={`${lang !== DEFAULT_LANG ? `/${lang}` : ''}/faq`}>
							{intl.formatMessage({ id: 'page.home.section.faq.cta' })}
						</Button>
					</p>
				</Text>
			</Section>
			<Section>
				<Text>
					<h2>{intl.formatMessage({ id: 'page.home.section.about.title' })}</h2>
					<p>{intl.formatMessage({ id: 'page.home.section.about.subtitle' })}</p>

					<Partners
						partners={[
							{
								name: 'CentralApp',
								description: intl.formatMessage({ id: 'page.home.partners.centralapp.desc' }),
								logo: '/img/centralapp.png',
								url: 'https://centralapp.com'
							},
							{
								name: 'Choco',
								description: intl.formatMessage({ id: 'page.home.partners.choco.desc' }),
								logo: '/img/choco.png',
								url: 'https://choco.com/fr-2/fr'
							},
							{
								name: 'Deliverect',
								description: intl.formatMessage({ id: 'page.home.partners.deliverect.desc' }),
								logo: '/img/deliverect.png',
								url: 'https://hubs.ly/H0BZbDY0'
							},
							{
								name: 'Deliveroo',
								description: intl.formatMessage({ id: 'page.home.partners.deliveroo.desc' }),
								logo: '/img/deliveroo.png',
								url: 'http://deliveroo.fr'
							},
							{
								name: 'Mapstr',
								description: intl.formatMessage({ id: 'page.home.partners.mapstr.desc' }),
								logo: '/img/mapstr.png',
								url: 'https://mapstr.com/'
							},
							{
								name: 'Rivalis',
								description: intl.formatMessage({ id: 'page.home.partners.rivalis.desc' }),
								logo: '/img/rivalis.png',
								url: 'https://www.pilotage-entreprise-rivalis.com/sos-restos?utm_source=centralapp&utm_medium=email&utm_campaign=SOS_RESTOS'
							},
							{
								name: 'Skello',
								description: intl.formatMessage({ id: 'page.home.partners.skello.desc' }),
								logo: '/img/skello.png',
								url: 'https://www.skello.io/'
							},
							{
								name: 'Swile',
								description: intl.formatMessage({ id: 'page.home.partners.swile.desc' }),
								logo: '/img/swile.png',
								url: 'https://www.swile.co/'
							},
							{
								name: 'Tiller',
								description: intl.formatMessage({ id: 'page.home.partners.tiller.desc' }),
								logo: '/img/tiller.png',
								url: 'https://www.tillersystems.com'
							}
						]}
					/>

					<p style={{ textAlign: 'center' }}>
						<FormattedMessage
							id="page.home.section.about.illustrations"
							values={{
								a: (...chunks) => (
									<a target="_blank" rel="noopener noreferrer" href="https://instagram.com/alixdebroux">
										{chunks}
									</a>
								)
							}}
						/>
					</p>
				</Text>
			</Section>
		</React.Fragment>
	);
}
