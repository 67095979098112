/*
 *
 */
import React from 'react';
import styled from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

/*
 * Styles
 */
const List = styled.ul`
	margin: 3.5rem auto;
	padding:0;
	list-style:none;
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	max-width:48em;
	font-size:1.5rem;
	line-height:1.5em;
`;


const Item = styled.li`
    text-align: left;
    
    padding: 2.45rem 3rem;
    margin-bottom:1.5rem;

	background: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 2rem 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-radius: 0.35rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

	@media (${props => props.theme.media.tablet}) {
   		flex-direction: row;
	}
`;

const Logo = styled.a`
	flex: 0 0 auto;
	box-sizing:border-box;
`;

const Identity = styled.div`
	flex: 1 1 auto;
	box-sizing:border-box;

	@media (${props => props.theme.media.tablet}) {
		margin-left:2.45rem;
	}
`;

const PartnerName = styled.p`
	margin:0;
	font-weight:500;
	font-size:1.7rem;
`;

const PartnerDesc = styled.p`
	margin:0.75rem 0;
`;

const PartnerLink = styled.a`
	color: #1b83a4;
	text-decoration: none;
	transition: 0.3s;
	margin-top: 2rem;
	font-weight: 500;
	margin-top: auto;

	&:hover {
		border-bottom: 1px solid #1b83a4;
	}
`;

const LogoImg = styled.img`

    border: 1px solid #f5f5f5;
    margin-bottom: 2rem;
    border-radius: 4px;
    width: 100%;

	@media (${props => props.theme.media.tablet}) {
		width: 18rem;
		margin-bottom: 0;
		border: #fff;
	}
`;

/*
 *
 */
export function Partners ({ partners=[] }) {
	return (
		<List>
			{partners.map((p, i) => (
				<Item key={i}>
					<Logo
						href={p.url} 
						target="_blank" 
						title={p.name}
						onClick={e => {
					        // Lets track that custom click
					        trackCustomEvent({
					          // string - required - The object that was interacted with (e.g.video)
					          category: "Image",
					          // string - required - Type of interaction (e.g. 'play')
					          action: "Go to Partner Website",
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
					          label: p.name
					        })
					      }}>
						<LogoImg src={p.logo} alt={p.name} />
					</Logo>
					<Identity>
						<PartnerName>{p.name}</PartnerName>
						<PartnerDesc>{p.description}</PartnerDesc>
						<PartnerLink 
							href={p.url} 
							target="_blank" 
							title={p.name}
							onClick={e => {
						        // Lets track that custom click
						        trackCustomEvent({
						          // string - required - The object that was interacted with (e.g.video)
						          category: "Link",
						          // string - required - Type of interaction (e.g. 'play')
						          action: "Go to Partner Website",
						          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
						          label: p.name
						        })
						      }}>Découvrez {p.name}</PartnerLink>
					</Identity>
				</Item>
			))}
		</List>
	);
}

export default Partners;
